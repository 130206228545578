import { FiArrowLeft, FiCopy } from "react-icons/fi";
import { TbReload } from "react-icons/tb"
import "./styles.css"
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { apiADM } from "../../../services/api_adm";
import Loading from "../../../components/loading";
import Modal from "../../../components/modal";

const ClientsDetails = () => {

    const { id } = useParams()
    const navigate = useNavigate()

    const [loading, setLoading] = useState({
        data: false,
        button: false
    })
    const [contribuinte, setContribuinte] = useState(null)
    const [assinaturas, setAssinaturas] = useState([])
    const [primeiro_vencimento, setPrimeiroVencimento] = useState("");
    const [assinaturaId, setAssinaturaId] = useState(1)
    const [valorAssinatura, setValorAssinatura] = useState(0)
    const [open, setOpen] = useState(false)

    const [keyIsCopied, setKeyIsCopied] = useState(false)


    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm();

    const valor_revenda = watch("valor_revenda")

    const getById = async () => {
        setLoading(old => ({ ...old, data: true }))

        try {
            const res = await apiADM.get(`contribuinte/get/${id}`)
            const obj_contribuinte = res.data?.contribuinte[0]
            const obj_assinaturas = res.data?.assinaturas

            setContribuinte(obj_contribuinte)
            setAssinaturas(obj_assinaturas)
            setAssinaturaId(obj_contribuinte.id_assinatura ?? 1)


            setValue("valor_revenda", obj_contribuinte.valor_revenda ?? 0)
            setValue("chave_pix", obj_contribuinte.chave_pix)

            const _assinaturaId = obj_contribuinte?.id_assinatura ?? 1

            const vAssinatura = (obj_assinaturas.find((item) => item.id === _assinaturaId)).valor
            setValorAssinatura(vAssinatura ?? obj_assinaturas[0].valor)
        } catch (err) {
            toast('Erro ao carregar os dados', { type: 'error' })
            throw err
        } finally {
            setLoading(old => ({ ...old, data: false }))
        }
    }

    const habilitarContribuinte = async ({ valor_revenda, chave_pix }) => {
        setLoading(old => ({ ...old, button: true }))
        try {
            const res = await apiADM.post("/habilitacao/ativar", {
                id_assinatura: assinaturaId,
                id_contribuinte: Number(id) || 0,
                valor_contratado: Number(valor_revenda),
                vencimento: primeiro_vencimento,

            })

            toast(res.data?.msg, { type: 'success' })

            if (res.data?.tokenCSC) {
                setContribuinte(old => ({ ...old, token_pass: res.data?.tokenCSC }))
            }
        } catch (err) {
            toast(err.response.data?.detail, { type: 'error' })
        } finally {
            setLoading(old => ({ ...old, button: false }))
        }
    }


    const getValorAssinatura = (id) => {
        return assinaturas.find((item) => item.id === id).valor
    }

    const handleChange = (e) => {
        // console.log(+e.target.value)
        setAssinaturaId(+e.target.value)
        const vAssinatura = getValorAssinatura(+e.target.value)
        setValorAssinatura(vAssinatura)
    }

    const handlPrimeiroVencimentoChange = (e) => {
        setPrimeiroVencimento(e.target.value);
    }

    const renovarAssinatura = async () => {
        try {
            const renovacao = await apiADM.post(`/cobranca/assinatura/renovacao?id_contribuinte=${id}`)
            toast(renovacao.data?.msg, { type: 'success' })
            setOpen(false)
        } catch (err) {
            toast(err.response.data?.detail, { type: 'error' })
        }
    }

    useEffect(() => {
        getById()
    }, [])

    if (loading.data) {
        return <Loading loadingContent />
    }

    return (
        <>
            <Modal isOpen={open} closeModal={() => setOpen(false)}>
                <h1>Renovar assinatura</h1>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 10 }}>
                    <span>Deseja renovar a assinatura?</span>
                    <div
                        className="button"
                        style={{ width: 85, padding: 7 }}
                        onClick={renovarAssinatura}
                    >
                        Confirmar
                    </div>
                </div>
            </Modal>
            <div className="details-container">
                <div className="details-header">
                    <span style={{ display: "inline-flex", alignItems: "center", gap: 12, width: "80%" }}>
                        <button className="icon-button" onClick={() => navigate(-1)}>
                            <FiArrowLeft size={20} color="white" />
                        </button>
                        <h3>{contribuinte?.fantasia}</h3>
                    </span>
                    <button className="button" style={{ display: "inline-flex", gap: 4, width: "180px" }} onClick={() => setOpen(true)}>
                        <TbReload size={20} color="white" />
                        Renovar Assinatura
                    </button>
                </div>
                <div className="details-body">
                    <h1>{contribuinte?.token_pass ? "Dados da assinatura do" : "Habilitar"} cliente</h1>
                    <div className="details-content">
                        <h4>Assinatura</h4>
                        <select
                            defaultValue={assinaturaId}
                            onChange={handleChange}
                            name="select">
                            {assinaturas?.map((item) => (
                                <option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {`${item.assinatura}`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="details-content">
                        <h4>Valor contratado</h4>
                        <input
                            type="number"
                            className="input input-details"
                            placeholder="valor"
                            {...register('valor_revenda',
                                {
                                    required: {
                                        value: true,
                                        message: "Valor é obrigatório.",
                                    }
                                }
                            )}
                        />
                        {errors?.valor_revenda && (
                            <div className="form-message-error">
                                <>{errors?.valor_revenda?.message}</>
                            </div>
                        )}
                    </div>

                    {(contribuinte?.token_pass === null && !contribuinte?.chave_ativacao) &&
                        <div className="details-content">
                            <h4>Data primeiro vencimento</h4>
                            <input
                                type="date"
                                {...register('first_due_date', {
                                    required: {
                                        value: true,
                                        message: "Data é obrigatória.",
                                    },
                                })}
                                onChange={handlPrimeiroVencimentoChange}
                                defaultValue={(() => {
                                    const currentDate = new Date();
                                    currentDate.setDate(currentDate.getDate() + 30);
                                    return currentDate.toISOString().split('T')[0];
                                })()}
                            />
                            {errors?.first_due_date && (
                                <div className="form-message-error">
                                    <>{errors?.first_due_date?.message}</>
                                </div>
                            )}
                        </div>
                    }

                    {
                        contribuinte?.token_pass &&
                        <div className="details-content">
                            <h4>Chave de ativação</h4>
                            <div className="key-active">
                                <button onClick={() => {
                                    navigator.clipboard.writeText(contribuinte?.token_pass)
                                    setKeyIsCopied(true)
                                    toast('Chave copiada!', { type: 'success', position: 'bottom-right' })
                                }} className="icon-button">
                                    <FiCopy size={30} color="white" />
                                </button>
                                <div>
                                    {contribuinte?.token_pass}
                                </div>
                            </div>

                        </div>
                    }

                    <button onClick={() => handleSubmit(habilitarContribuinte)()} disabled={loading.button} className="button save-details">
                        {loading.button ? <Loading /> : contribuinte?.token_pass ? "Salvar" : "Habilitar"}
                    </button>
                </div>
            </div>
        </>
    )
}

export default ClientsDetails;