import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { apiAUTH } from "../services/api_auth";
import { isExpired, decodeToken } from "react-jwt";
import { getTokenStorage } from "../services/serviceAuth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const token = getTokenStorage()
    if(token){
     return decodeToken(token)
    }
   return null
  })
  const navigate = useNavigate()
  const [custom, setCustom] = useState(null)

  const signin = async (username, password) => {
    try {
      const response = await apiAUTH.post('/auth/user/login', { username, password, grupo: "Revenda" })
      if (response) {
        if (response.data.user && response.data.access_token) {
          if(response.data.user.grupo !== "Revenda") {
            throw "Acesso negado"
          }
          setUser(response.data.user);
          localStorage.setItem('accessToken', JSON.stringify(response.data.access_token))
          localStorage.setItem('user', JSON.stringify(response.data.user))
          navigate("/clientes")
        }
      }
    } catch(err) {
      throw err
    }
  };

  const signout = () => {
    setUser(null);
    localStorage.removeItem('accessToken');
    navigate('/login')
  }

  const validateToken = async (token) => {
    if (isExpired(token)) {
      signout()
    }
  };

  useEffect(() => {
    const token = getTokenStorage()
    if(token) {
      validateToken(token)
    }
  }, [])


  return (
    <AuthContext.Provider value={{ user, setUser, custom, setCustom, signin, signout, signout }}>
      {children}
    </AuthContext.Provider>
  );

}

export function useAuth() {
  return useContext(AuthContext)
}