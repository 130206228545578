import { apiAUTH } from './api_auth';
import { useAuth } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'


export const ServiceAuth = () => {
    const { setUser, setCustom } = useAuth();
    const navigate = useNavigate();


    const serviceSignin = async (username, password) => {   
        const response = await apiAUTH.post('/auth/user/login', {username, password})    
        if (response) {
            if (response.data.user && response.data.access_token){
                setUser(response.data.user);
                localStorage.setItem('accessToken', JSON.stringify(response.data.access_token))    
                navigate("/dashboard");   
                return true;
            } 
        }
        return false;
    };

    const serviceCoringas = async (username) => {    
        const response = await apiAUTH.post('/password/coringas', {username})    
        if (response) {
            console.log('Lista coringas: ', response.data)
            setCustom(response.data)
            navigate('/login/coringas')
            return response.data;
           
        }
        return null;
    };

    const VerifyCode = async (coringa) => {    
        const response = await apiAUTH.post('/password/sendcode', {coringa})    
        if (response) {
            console.log('Result Code: ', response.data)
            navigate('/login/verifycode')
            return response.data;
           
        }
        return null;
    };
    
    const Newpassword = async (code) => {    
        const response = await apiAUTH.post('/password/sendcode', {code})    
        if (response) {
            console.log('Result Code: ', response.data)
            navigate('/login/newpassword')
            return response.data;
           
        }
        return null;
    };

    return ({serviceSignin, serviceCoringas, VerifyCode, Newpassword});
  
}



export const ValidateToken = async (token) => {
    try {
        const response = await apiAUTH.post('/auth/validate', {token})
        if (response) 
            return response;
        
    } catch (error) {
        console.log('aaa: ', error.request.status)        
        return null;       
    }
};

export const getTokenStorage = () => {    
    const token = localStorage.getItem('accessToken');
        if (!token) {
            return '';        
        }
        const usertoken = JSON.parse(token);
    
        return usertoken
};



