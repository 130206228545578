import { Outlet } from 'react-router-dom'
import "./styles.css"

import Sidebar from '../sidebar/Sidebar';
import Profile from '../../components/profile/Profile';

const MainLayut = () => {
    return (
        <div className={`container`}>
            <div className="header">
                <div className="text-panel">Painel da revenda</div>
                <div className="header-right">
                    <Profile />
                </div>
            </div>
            <div className="sidebar-layout">
                <Sidebar />
            </div>
            <div className="main">
                <Outlet />
            </div>
            <div className="footer">
                Alpha tecnologia {new Date().getFullYear()} - Todos os direitos reservados
            </div>
        </div>
    )
}
export default MainLayut;