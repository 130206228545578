import axios from "axios";
import { getTokenStorage } from '../services/serviceAuth'

export const apiADM = axios.create({ baseURL: process.env.REACT_APP_API_ADM });

apiADM.interceptors.request.use(async (config) => {
        const token = getTokenStorage();
        if (token) {
                config.headers.Authorization = `Bearer ${token}`
                return config
        } else {
                return config
        }
});