import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

import { FaSync } from "react-icons/fa"
import { FiSearch } from "react-icons/fi"
import { toast } from "react-toastify"

import "./styles.css"

import { apiADM } from "../../services/api_adm"
import { apiInsc } from "../../services/api_insc"

import { getUserLocalStorage } from "../../helpers/auth"
import Loading from "../../components/loading"

import List from "./list"
import { tabData } from "./tab-data"

const Clients = () => {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [status, setStatus] = useState("habilitado")

    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm();
    const { id_revenda } = getUserLocalStorage()


    const get_contribuinte = async (fragment) => {
        setLoading(true)
        try {
            const res = await apiADM.get("/contribuinte/get", {
                params: { id_revenda, fragment, status }
            })
            setData(res?.data?.contribuintes)
        } catch (err) { return toast(`Erro ao busca contribuintes`, { type: 'error' }) }
        finally { setLoading(false) }
    }


    const onSubmit = ({ fragment }) => {
        get_contribuinte(fragment)
    }

    const sincronizarContribuintes = async () => {
        setLoading(true)
        try {
            await apiInsc.post("/contribuinte/get/news", {
                id_revenda
            })
            toast(`Dados sincronizados`, { type: 'success' })
            get_contribuinte()
        } catch (err) {            
            toast(`Erro ao sincronizar os dados`, { type: 'error' })
            return
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        get_contribuinte(null)
    }, [status])

    return (
        <div className="clients-conteiner">
            <div className="clients-header">
                <div className="search-box">
                    <h2>Lista de clientes</h2>
                    <div>
                        <button onClick={sincronizarContribuintes} className="icon-button">
                            {loading ? <Loading /> : <FaSync color="white" size={16} />}
                        </button>
                    </div>
                </div>

                <div className="search-client-container">
                    <FiSearch className="input-search-icon" />
                    <input
                        {...register("fragment")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleSubmit(onSubmit)()
                            }
                        }}
                        disabled={loading}
                        className="input"
                        placeholder="Digite alguma coisa...">

                    </input>

                    <button
                        onClick={() => handleSubmit(onSubmit)()}
                        className="button search-clients">
                        pesquisar
                    </button>
                </div>
            </div>
            <div className="clients-list">
                <div className="clients-list-header">
                    <div className="tabs-container">
                    <div className="tabs-content">
                        {
                            tabData.map((_tab) => (
                                <button disabled={loading} onClick={() => setStatus(_tab.status)} className={`button-tab ${status === _tab.status && "active"}`}>
                                    {_tab.label}
                                </button>
                            ))
                        }
                    </div>
                    </div>
                    <h3>{data?.length ?? 0} Registros</h3>
                </div>
                <List data={data} loading={loading} />
            </div>
        </div>
    )
}

export default Clients