import { useState } from "react";
import { FiEye, FiEyeOff, FiLock, FiUser } from "react-icons/fi"

import { useForm } from "react-hook-form";
import validator from 'validator'

import { Images } from "../../../assets/intex";
import { useAuth } from "../../../context/AuthContext";
import { toast } from "react-toastify";
import Loading from "../../../components/loading";

import "./styles.css"

const LoginPage = () => {

    const { signin } = useAuth()

    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false)

    const handleLogin = async ({ email, password }) => {
        setLoading(true)
        try {
            await signin(email, password)
          } catch (err) {
            toast(`Autenticação: ${err?.response?.data?.detail}`, { type: 'error' })
          } finally {
            setLoading(false)
          }
    }

    return (
        <div className="container-login ">
            <div className="login-left">
                <div className="container-left">
                    {/* <img alt="logo" src={Images.logo} className="logo-rota" /> */}
                    <div className="login-right-conteiner">
                        {/* <h1>Integração do serviço <br /> PIX</h1>
                        <h4 className="descritption">Aumente sua receita, habilitando seus clientes na <br /> integração do serviço pix.</h4>
                        <div className="resales">
                            <div className="resales-group">
                                <span className="resale">
                                    <img src="https://bit.ly/prosper-baba" alt="Christian Nwamba" class="chakra-avatar__img css-3a5bz2"/>
                                </span>
                                <span className="resale">
                                    <img src="https://bit.ly/sage-adebayo" alt="Christian Nwamba" class="chakra-avatar__img css-3a5bz2"/>
                                </span>
                                <span className="resale">
                                    <img src="https://bit.ly/kent-c-dodds" alt="Christian Nwamba" class="chakra-avatar__img css-3a5bz2"/>
                                </span>
                                <span className="resale">
                                    <img src="https://bit.ly/code-beast" alt="Christian Nwamba" class="chakra-avatar__img css-3a5bz2"/>
                                </span>
                                <span className="resale">
                                    <img src="https://bit.ly/ryan-florence" alt="Christian Nwamba" class="chakra-avatar__img css-3a5bz2"/>
                                </span>
                            </div>
                            <h4>Revendas inscritas</h4>
                        </div> */}
                    </div>
                    {/* <footer>© 2023 Rotta Tecnologia LTDA. All rights reserved.</footer> */}
                </div>
            </div>
            <div className="login-rigth light">
                <div className='login-body'>
                    <div className='login-header'>
                        <h1>Login</h1>
                    </div>
                    <div className='login-form'>
                        <div>
                            <div className='input-user'>
                                <label id="email-label" htmlFor="email" >Email
                                    <span role="presentation" aria-hidden="true">*</span>
                                </label>
                                <div className='input-box-user'>
                                    <FiUser className='input-icon' />
                                    <input
                                        type='text'
                                        placeholder='Email'
                                        name="email"
                                        {...register('email',
                                            {
                                                required: {
                                                    value: true,
                                                    message: "Email é obrigatório."
                                                },
                                                validate: (value) => {
                                                    if (!validator.isEmail(value)) {
                                                        return "Email é inválido."
                                                    }
                                                }
                                            }
                                        )}
                                    />
                                    {errors?.email && (
                                        <div className="form-message-error">
                                            <>{errors?.email?.message}</>
                                        </div>
                                    )}
                                </div>
                                <div className='input-box-pass'>
                                    <label id="password-label" htmlFor="password">Senha
                                        <span role="presentation" aria-hidden="true">*</span>
                                    </label>
                                    <FiLock className='input-icon' />
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder='Password'
                                        autoComplete="current-password"
                                        {...register('password',
                                            {
                                                required: {
                                                    value: true,
                                                    message: "Senha é obrigatório."
                                                },
                                                minLength: {
                                                    value: 4,
                                                    message: "Senha deve ser maior ou igual a 4 digitos."
                                                }
                                            })}
                                    />
                                     <button className='button-show-pass' onClick={() => setShowPassword(!showPassword)}>
                                       {showPassword ? <FiEye /> : <FiEyeOff />}
                                    </button>
                                    {errors?.password && (
                                        <div className="form-message-error">
                                            <>{errors?.password?.message}</>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <button disabled={loading} onClick={() => handleSubmit(handleLogin)()} className='button btn-login'>
                                {loading ? <Loading /> : "Entrar"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default LoginPage;