import { useNavigate } from "react-router-dom";

import { FaCheckCircle } from "react-icons/fa";
import { FiAlignJustify } from "react-icons/fi";

import Loading from "../../components/loading";

const List = ({ data, loading }) => {

    const navigate = useNavigate()

    if (loading) {
        return <Loading loadingContent />
    }

    return (
        <div className="list-clients-container">
            <section className="table-body">
                <table responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>CNPJ</th>
                            <th>Cidade</th>
                            <th>Contato</th>
                            <th>Assinatura/Expira</th>
                            <th>Situação</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((cliente, i) => (
                            <tr key={cliente.id}>
                                <td>{cliente?.id}</td>
                                <td>
                                    <div style={{ color: '#fffb' }}><strong> {cliente?.fantasia}</strong></div>
                                    <div>{cliente?.razao_social}</div>
                                    <div>{cliente?.cnpj}</div>
                                </td>
                                <td>{cliente.cidade}</td>
                                <td>
                                    <div>{cliente.contato}</div>
                                    <div>{cliente.email}</div>
                                    <div>{cliente.celular}</div>
                                </td>
                                <td>
                                    <strong>{cliente.assinatura}</strong>
                                    <div>{cliente.dt_expires_assin ? `Expira: ${cliente.dt_expires_assin}` : ""}</div>
                                </td>
                                <td>
                                    <FaCheckCircle color={!cliente.is_habilitado ? "gray.300" : cliente.vencido ? 'orange' : 'green'} size={'1.25rem'} />
                                </td>
                                <td>
                                    <button
                                        onClick={() => navigate(`/clientes/${cliente.id}`)}
                                        className="icon-button search-icon">
                                        <FiAlignJustify color="white" size={22}/>
                                    </button>
                                </td>
                            </tr>
                        ))}
                        <div className="end-list" />
                    </tbody>
                </table>
            </section>
        </div>
    )
}

export default List;
