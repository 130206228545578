import React from "react";
import { useRoutes, Navigate, Outlet } from "react-router-dom";

import LoginPage from "../pages/auth/login";
import PrivateRoute from "./private";
import Clients from "../pages/clients";
import MainLayut from "../layouts/main";
import PublicRoute from "./public";
import ClientsDetails from "../pages/clients/details";
import Dashboard from "../pages/dashboard";

const router = () => {
    return useRoutes([
        {
            path: "/",
            element: <PrivateRoute> <MainLayut /> </PrivateRoute>,
            children: [
                {
                    path: "/",
                    element: <Navigate to="/clientes"/>,
                },
                {
                    path: "/*",
                    element: <Navigate to="/clientes"/>,
                },
                {
                    path: "/clientes",
                    element: <Clients />,
                },
                {
                    path: "/clientes/:id",
                    element: <ClientsDetails />,
                },
                {
                    path: "/dashboard",
                    element: <Dashboard />,
                },
            ]
        },
        {
            path: "/",
            element: <PublicRoute> <Outlet /> </PublicRoute>,
            children: [
                {
                    path: "/",
                    element: <Navigate to="/login"/>,
                },
                {
                    path: "*",
                    element: <Navigate to="/login"/>,
                },
                {
                    path: "/login",
                    element: <LoginPage />,
                }
            ]
        },
        
       
    ])
};


export default router