import React, { useContext } from 'react';

import { AuthContext } from '../../context/AuthContext';
import { FiLogOut, FiUser } from 'react-icons/fi';

import './index.css';

const Profile = () => {
  const { user, signout } = useContext(AuthContext);
  return (
    <div className='profile'>
      <FiUser size={30} />
      <div className='profile-text'>
        <div className='profile-text-user'>{user?.nome_usuario}</div>
        <div>{user?.role}</div>
      </div>
      <button className="logout-user" onClick={signout}>
        <i><FiLogOut size={30}/></i>
      </button>
    </div>
  );
};

export default Profile;
