import axios from "axios";
import { getTokenStorage } from '../services/serviceAuth'

export const apiInsc = axios.create({
    baseURL: process.env.REACT_APP_API_INSC
});

apiInsc.interceptors.request.use(async (config) => {
    const token = getTokenStorage();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
        return config
    } else {
        return config
    }
});