import "./style.css"

export default function Modal({ isOpen, closeModal, children }) {
    return (
        <div className={`modal ${isOpen ? "open" : ""}`}>
            <div className="modal__content">
                {children}
                <span onClick={closeModal} className="modal__close">&times;</span>
            </div>
        </div>
    )
}