import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";

import { Images } from "../../assets/intex";
import { CgHome } from 'react-icons/cg'
import { FiUsers } from "react-icons/fi";

import './index.css';

const Sidebar = () => {

  const location = useLocation()

  const [activeLink, setActiveLink] = useState('');

  const handleLinkClick = (linkId) => {
    setActiveLink(linkId);
  };

  useEffect(() => {
    setActiveLink(location.pathname.split("/")[1])
  }, [location])

  return (
    <div className='sidebar-container'>
      <div className='sidebar-header'>
        <img src={Images.minAlphaLogo} alt='sidebar-logo' />
        <div className='sidebar-header-text'>
          <div className='sidebar-logo-text1'>Alpha</div>
          <div className='sidebar-logo-text2'>tecnologia</div>
        </div>
      </div>
      <div>
        <div>
          <ul>
            <li className={`sidebar-nav-link ${activeLink === 'dashboard' ? 'active' : ''}`} onClick={() => handleLinkClick('dashboard')}>
              <Link to='dashboard'>
                <i><CgHome /></i>
                <div className='nav-link'>Dashboard</div>
              </Link>
            </li>
            <li className={`sidebar-nav-link ${activeLink === 'clientes' ? 'active' : ''}`} onClick={() => handleLinkClick('clientes')}>
              <Link to='clientes'>
                <i><FiUsers /></i>
                <div className='nav-link'>Clientes</div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;