export const getTokenStorage = () => {
    const token = localStorage.getItem('accessToken');
    if (!token) { return ''; }
    const usertoken = JSON.parse(token);
    return usertoken
};

export const getUserLocalStorage = () => {
    const user = localStorage.getItem('user')
    return !user? null : JSON.parse(user);
}